import { useEffect, useState } from "react";
import { API_ENDPOINTS } from "../constants/api";
import Http from "../services/http";
import { BaseEntityRes } from "../types/base";

// "static" variable
const _supportedCountries: string[] = [];

interface SupportedCountriesRes extends BaseEntityRes {
  supportedCountries: string[];
}

export const useVerifyCountryEffect = () => {
  const [supportedCountries, setSupportedCountries] =
    useState<string[]>(_supportedCountries);

  useEffect(() => {
    // prevent multiple API calls
    if (_supportedCountries.length > 0) return;

    getSupportedCountries();
  }, []);

  const getSupportedCountries = async () => {
    const { supportedCountries }: SupportedCountriesRes = await Http.get(
      API_ENDPOINTS.EMAIL_SUPPORTED_COUNTRIES,
      undefined,
      true
    );
    setSupportedCountries(supportedCountries);
  };

  /**
   * Check if a country supports logging in via email
   *
   * @param countryCode ISO2 standard country code of a country
   * @return `true` if the country is supported. `false`, otherwise.
   */
  const checkSupport = (countryCode: string) => {
    return supportedCountries.includes(countryCode);
  };

  return { checkSupport };
};
